nav {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1000;
}

.header-container {
  background-color: #25848d1a;
  display: flex;
  justify-content: space-between;
  padding-left: 10vw;
  padding-right: 10vw;
  align-items: center; /* Ensure vertical alignment */
}

.logo-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: transparent; /* Remove background-color if not needed */
}

.logo-main {
  width: 80px;
  margin-right: 5px; /* Add spacing between the logo and the text */
}

.infinitude {
  width: 150px;
}

.header-buttons {
  color: #17565a;
  transition: color 0.4s;
  font-weight: 800;
}

.header-buttons:hover {
  color: white;
}

.div-header {
  /* background-color: rgba(0, 0, 0, 0.8); */
  height: 80px;
  /* padding: 0 40px; */
  flex-direction: row;
  display: flex;
  align-items: center;
}

.header-buttons {
  height: 40px;
  padding: 0 10px;
  background-color: transparent;
  border: 0;
  font-size: 14px;
  font-weight: 500;
  margin: 0 10px;
  cursor: pointer;
  border-radius: 10px;
}

.header-buttons:hover {
  /* box-shadow: 0 5px navy; */
  color: #17565a;
  transition: color 0.3s;
}

.header-button-line {
  background-color: #17565a;
  border-radius: 10px;
  height: 4px;
  /* width: 0; */
  transition: width 2s;
}

#header-button-line {
  transition-timing-function: ease-in-out;
}

.header-button-line:hover {
  width: auto;
}

@media (max-width: 961px) {
  nav {
    padding: 0; /* Remove padding for mobile */
  }

  .header-container {
    padding: 0 20px; /* Adjust padding for mobile */
    background-color: #25848d1a; /* Semi-transparent background */
  }

  .logo-container {
    justify-content: center; /* Center logo on mobile */
    padding: 10px 0; /* Add vertical padding */
  }

  .logo-main {
    width: 60px; /* Adjust logo size for mobile */
    margin-right: 0; /* Remove margin */
  }

  .infinitude {
    width: 120px; /* Adjust logo size for mobile */
  }

  .div-header {
    display: none; /* Hide the regular menu for mobile */
  }

  .popover-arrow-container {
    /* Style for popover */
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    position: absolute; /* Position popover correctly */
    right: 0;
  }

  .header-buttons {
    display: block; /* Ensure buttons are visible */
    color: #17565a;
    font-size: 16px; /* Adjust font size for mobile */
    padding: 12px 20px; /* Adjust padding */
    margin: 5px 0; /* Adjust margin */
    border-radius: 10px;
    text-align: center; /* Center text in buttons */
  }

  .header-buttons:hover {
    color: #ffffff;
    background-color: #17565a; /* Darker background on hover */
    border-color: #17565a; /* Border color on hover */
  }

  .menu-button {
    background: transparent;
    border: none;
    padding: 0;
    cursor: pointer;
    color: #17565a;
  }
}
