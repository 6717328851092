/* Base Styles */
.service-main-container {
  height: auto;
}

.service-header-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: row;
  min-height: 70vh;
}

.services {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 20px 50px;
  gap: 100px;
  background-image: url("../../assets/background.svg");
  background-repeat: repeat;
  background-size: 100vw 100vh;
  background-position: top left;
}

.services-img {
  width: 35vw;
  margin-top: 20px;
  margin-bottom: 20px;
  mix-blend-mode: multiply;
  /* border: 2px solid red; */
}

.service-header-description {
  width: 40vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.service-header-description h3 {
  font-size: 30px;
  font-weight: 600;
  text-align: center;
  line-height: 1.2; /* Adjusted for better readability */
  color: #25848d;
  margin-bottom: 20px;
}

.service-header-description p {
  font-size: 20px;
  text-align: justify;
  line-height: 1.5; /* Adjusted for better readability */
  margin-top: 20px;
}

.service-section {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 100px;
  padding: 20px 0;
  min-height: 60vh;
}

.services-list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  list-style: none; /* Custom bullets */
  padding-left: 0; /* Adjusted padding */
  margin: 0;
}

.services-list li {
  margin: 0 20px; /* Spacing between items */
  font-size: 16px;
  font-weight: bold;
  position: relative;
  padding-left: 30px; /* Space for the bullet */
}

.services-list li::before {
  content: "•"; /* Bullet character */
  position: absolute;
  left: 0;
  font-size: 20px;
  color: #25848d; /* Bullet color */
  line-height: 1; /* Bullet alignment */
}

.service-section img {
  width: 30vw;
  mix-blend-mode: multiply;
}

.service-section h3 {
  font-size: 30px;
  font-weight: 600;
  text-align: center;
  line-height: 1.2;
  color: #25848d;
  margin-bottom: 20px;
}

.service-description {
  width: 40vw;
}

.service-description p {
  text-align: justify;
  font-size: 20px;
}

/* Primary button */
.service-button {
  background: linear-gradient(
    135deg,
    #00b4db,
    #0083b0
  ); /* Gradient background */
  border: none;
  border-radius: 25px; /* Rounded corners */
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  padding: 12px 24px;
  text-transform: uppercase;
  cursor: pointer;
  transition: background 0.3s, transform 0.3s;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2); /* Shadow effect */
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

/* Arrow icon */
.service-button .arrow-icon {
  margin-left: 8px;
  transition: transform 0.3s;
}

/* Hover effect */
.service-button:hover {
  background: linear-gradient(135deg, #0083b0, #00b4db); /* Inverted gradient */
  transform: scale(1.05); /* Slightly larger on hover */
}

.service-button:hover .arrow-icon {
  transform: translateX(5px); /* Move arrow on hover */
}

/* Focus effect */
.service-button:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.2); /* Focus ring */
}

/* Media Queries */
@media (max-width: 641px) {
  /* Smartphones and portrait phones */
  .service-header-description,
  .service-description {
    width: 80vw; /* Full width on small screens */
  }

  .service-header-container {
    flex-direction: column;
  }

  .service-section {
    display: flex;
    flex-direction: column;
    gap: 10px;
    min-height: auto;
    justify-content: center;
    align-items: center;
  }

  .service-description p {
    font-size: 14px;
  }

  .service-header-description p {
    font-size: 14px;
  }

  .service-header-description h3 {
    font-size: 18px;
  }

  .services-img,
  .service-section img {
    width: 35vw; /* Full width images */
  }

  .service-section h3 {
    font-size: 16px;
  }

  .services-list {
    display: flex;
    flex-direction: column; /* Stack list items vertically */
    justify-content: center;
    align-items: flex-start;
  }

  .services-list li {
    margin: 5px 0; /* Vertical spacing between items */
    font-size: 14px;
  }

  .service-button {
    font-size: 10px;
  }
}

@media (min-width: 641px) and (max-width: 961px) {
  /* Tablets and smaller desktops */
  .service-header-description,
  .service-description {
    width: 50vw; /* Adjust width for tablets */
  }

  .service-section {
    flex-direction: row;
    gap: 70px; /* Adjust gap */
  }

  .services-img,
  .service-section img {
    width: 35vw; /* Adjust width for tablets */
  }

  .services-list {
    flex-direction: row; /* Keep list items horizontal */
    padding-left: 20px; /* Adjust padding */
  }
}

@media (min-width: 962px) {
  /* Larger screens */
  .service-header-description,
  .service-description {
    width: 40vw; /* Standard width */
  }

  .service-section {
    gap: 100px; /* Standard gap */
  }

  .services-img,
  .service-section img {
    width: 35vw; /* Standard width */
  }

  .services-list {
    flex-direction: row; /* Horizontal list items */
  }
}
