.tech-title {
  text-align: center;
  margin-top: 20px;
  font-size: 20px;
  font-weight: 600;
}

.tech-icon {
  padding: 0 60px;
}

@media (max-width: 641px) {
  .tech-title {
    text-align: center;
    margin-top: 20px;
    font-size: 12px;
    font-weight: 600;
  }
}
