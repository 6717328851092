/* @import url(https://maxcdn.bootstrapcdn.com/font-awesome/4.4.0/css/font-awesome.min.css);
@import url(https://fonts.googleapis.com/css?family=Raleway:400,800); */
/* .testimonial {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  padding: 100px;
}

.testimonial-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
}

figure {

  width: 33%;

  color: black;
  text-align: left;
  box-shadow: none !important;
  padding: 20px;
}
figure * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.35s cubic-bezier(0.25, 0.5, 0.5, 0.9);
  transition: all 0.35s cubic-bezier(0.25, 0.5, 0.5, 0.9);
}
figure img {
  vertical-align: middle;
  height: 70px;
  width: 70px;
  padding: 10px;
  border-radius: 50%;
  margin: 40px 0 0 10px;
  background-color: #25848d3a;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.2);
}
figure blockquote {
  display: block;
  border-radius: 8px;
  position: relative;
  background-color: #25848d3a;
  padding: 25px 50px 30px 50px;
  font-size: 0.8em;
  font-weight: 500;
  margin: 0;
  line-height: 1.6em;
  font-style: italic;
  border-radius: 20px;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.2);
  background: linear-gradient(135deg, #25848d3a 0%, #ffffff 100%);
}
figure blockquote:before,
figure blockquote:after {
  font-family: "FontAwesome";
  content: "\201C";
  position: absolute;
  font-size: 50px;
  opacity: 0.3;
  font-style: normal;
}
figure blockquote:before {
  top: 25px;
  left: 20px;
}
figure blockquote:after {
  content: "\201D";
  right: 20px;
  bottom: 0;
}
figure .arrow {
  top: 100%;
  width: 0;
  height: 0;


  border-right: 25px solid transparent;

  border-top: 25px solid #25848d3a;


  box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.2);

  margin: 0;
  position: absolute;
}

.testimonial-name {
  display: flex;
  flex-direction: row;

  align-items: center;
}
figure .author {

  margin: 0;
  text-transform: uppercase;
  color: #000;
  -webkit-transform: translateY(50%);
  transform: translateY(50%);
}
figure .author h5 {
  opacity: 0.8;
  margin: 0;
  font-weight: 800;
  margin-left: 20px;
}
figure .author h5 span {
  font-weight: 400;
  text-transform: none;
  padding-left: 5px;
}


@media (max-device-width: 961px) {

  .testimonial {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;
    padding-top: 80px;
    padding-left: 20px;
    padding-right: 20px;
  }
  figure {
    font-family: "Raleway", Arial, sans-serif;
    position: relative;
    overflow: hidden;
    margin: 10px;

    width: 100%;
    color: white;
    text-align: left;
    box-shadow: none !important;
    padding: 20px;
  }
} */
.testimonial {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  padding: 100px;
}

.testimonial-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  margin: 60px 0;
  gap: 40px;
}

.testimonial-card {
  background: linear-gradient(135deg, #25848d3a 0%, #ffffff 100%);
  border-radius: 12px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  overflow: hidden; /* Ensure no overflow */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  max-width: 400px; /* Maximum width for the card */
  margin: 20px auto; /* Center horizontally with margin */
  position: relative;
}

.testimonial-logo img {
  width: 80px; /* Logo width */
  height: auto;
  padding: 5px; /* Padding inside the border */
}

.testimonial-content {
  text-align: justify;
}

.testimonial-company-name {
  font-size: 18px;
  font-weight: bold;
  color: #25848d; /* Company name color */
  margin: 10px 0;
  text-align: center;
}

.testimonial-story {
  font-size: 16px;
  color: #333333; /* Text color */
  line-height: 1.5;
}

.testimonial-card::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 10px; /* Decorative top border */
  background: linear-gradient(135deg, #00b4db, #0083b0); /* Gradient effect */
  border-bottom: 2px solid #ffffff; /* Border to separate from content */
}
