* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  /* background: #fcfcfc; */
  font-family: sans-serif;
}

footer {
  /* position: absolute; */
  bottom: 0;
  left: 0;
  right: 0;
  /* background: #f0f1f6; */
  height: auto;
  box-shadow: 0 20px 40px 0 gray;
  /* padding-top: 40px; */
  background-color: #25848d1a;
  border: 0;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  flex-direction: column;
}

.brand-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.brand-container button {
  margin-top: 10px;
  font-size: 14px;
  border: 0;
  background-color: hsl(184, 59%, 22%);
  color: #fff;
  width: fit-content;
  padding: 10px;
  cursor: pointer;
}

.brand-container button:hover {
  opacity: 0.8;
}

.brand-container button:active {
  opacity: 0.5;
}

.footer-content {
  display: flex;
  /* align-items: center; */
  justify-content: center;
  gap: 100px;
  flex-direction: row;
  /* text-align: center; */
  padding: 20px;
  width: 60%;
}
.footer-content h3 {
  font-size: 2.1rem;
  font-weight: 500;
  text-transform: capitalize;
  line-height: 3rem;
}
.footer-content p {
  max-width: 500px;
  margin: 10px auto;
  line-height: 28px;
  font-size: 14px;
  color: #cacdd2;
}
.socials {
  list-style: none;
  display: flex;
  /* align-items: center; */
  justify-content: center;
  margin-top: 1rem;
}
.socials li {
  margin: 0 10px;
}
.socials a {
  text-decoration: none;
  color: #fff;
  /* border: 1.1px solid white; */
  padding: 5px;

  border-radius: 50%;
}
.socials a i {
  font-size: 1.1rem;
  width: 20px;
  transition: color 0.4s ease;
}
.socials a:hover i {
  color: aqua;
}

.footer-bottom {
  /* background: #f0f1f6; */
  /* padding: 20px; */
  font-size: 13px;
  height: 50px;
  /* padding-bottom: 40px; */
  text-align: center;
  /* padding-left: 40px; */
  /* padding-right: 20px; */
  display: flex;
  justify-content: center;
  border-top: 0.8px solid #17565a;
  align-items: center;
  width: 75%;
}
.footer-bottom p {
  float: left;
  font-size: 13px;
  word-spacing: 2px;
  text-transform: capitalize;
  color: #000;
}
.footer-bottom p text {
  color: #17565a;
  font-size: 15px;
  text-decoration: none;
}
.footer-bottom span {
  text-transform: uppercase;
  opacity: 0.4;
  font-weight: 200;
}
.footer-menu {
  opacity: 0.8;
  color: #000;
  display: flex;
  flex-direction: column;
}
.footer-menu ul {
  display: flex;
  flex-direction: column;
  font-size: 15px;
  list-style: none;
  padding-left: 0px;
  margin-top: 10px;
  /* justify-content: flex-start; */
}
.footer-menu h4 {
  /* font-size: 15px; */
  /* justify-content: flex-start; */
  /* margin-left: 25px; */
  margin-top: 10px;
  font-weight: 700;
  color: #17565a;
}
.footer-menu ul li {
  /* padding-right: 10px; */
  /* display: block; */
  cursor: pointer;
  padding-bottom: 5px;
  text-align: left;
}
.footer-menu ul li a {
  color: #000;
  text-decoration: none;
  align-items: center;
  display: flex;
}

.footer-menu ul li p {
  color: #000;
  text-decoration: none;
  align-items: center;
  display: flex;
  height: 0;
  gap: 0;
}

.footer-menu ul li text:hover {
  color: #6677a8;
  text-decoration: none;
}
.footer-menu ul li a:hover {
  color: #6677a8;
}

/* .logo {
  height: 80px;
  width: 200px;
  margin-top: 15px;
} */

.footer-logo-container {
  width: 200px;
  overflow: hidden;
  margin-left: 15px;
}

.logo {
  width: 100%; /* Make the image fill the container width */
  height: auto; /* Maintain the aspect ratio */
  display: block; /* Remove extra space below the image */
}

@media (max-width: 496px) {
  footer {
    /* position: absolute; */
    bottom: 0;
    left: 0;
    right: 0;
    height: auto;
    box-shadow: 0 2px 4px 0 gray;
    /* padding-top: 40px; */
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .footer-content {
    display: flex;
    /* align-items: center; */
    justify-content: space-between;
    flex-direction: column;
    /* text-align: center; */
    padding: 20px;
    width: 60%;
  }

  .footer-menu ul {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    list-style: none;
    padding-left: 0px;
    /* justify-content: flex-start; */
  }
}

@media (min-width: 496px) and (max-width: 750px) {
  footer {
    /* position: absolute; */
    bottom: 0;
    left: 0;
    right: 0;
    height: auto;
    box-shadow: 0 2px 4px 0 gray;
    /* padding-top: 40px; */
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .footer-content {
    display: flex;
    /* align-items: center; */
    justify-content: space-between;
    flex-direction: column;
    /* text-align: center; */
    padding: 20px;
    width: 60%;
  }

  .footer-menu ul {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    list-style: none;
    padding-left: 0px;
    /* justify-content: flex-start; */
  }
}

@media (min-width: 750px) and (max-width: 1000px) {
  footer {
    /* position: absolute; */
    bottom: 0;
    left: 0;
    right: 0;
    height: auto;
    box-shadow: 0 2px 4px 0 gray;
    /* padding-top: 40px; */
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .footer-content {
    display: flex;
    /* align-items: center; */
    justify-content: space-between;
    flex-direction: row;
    /* text-align: center; */
    padding: 20px;
    width: 100%;
  }

  .footer-menu ul {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    list-style: none;
    padding-left: 0px;
    /* justify-content: flex-start; */
  }
}
