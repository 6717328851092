.contact-main-container {
  height: auto;
  padding: 100px 0;
  background-image: url("../../assets/circles.svg");
  background-repeat: repeat; /* Repeat the background image */
  background-size: 100%; /* Prevent stretching */
  background-position: top left; /* Position the image at the top left */
}

.contact-form-card {
  background: #ffffff; /* Card background color */
  border-radius: 12px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  padding: 20px;
  width: 40vw;
  margin: 20px; /* Center horizontally with margin */
  position: relative;
  border: 1px solid #e0e0e0; /* Light border for definition */
  margin-bottom: 80px;
}

.contact-form-header {
  font-size: 24px;
  font-weight: bold;
  color: #25848d; /* Header color */
  text-align: center;
  margin-bottom: 20px;
}

.form__group {
  position: relative;
  margin-bottom: 20px;
}

.form__field {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc; /* Input border color */
  border-radius: 8px; /* Rounded corners for inputs */
  font-size: 16px;
  box-sizing: border-box; /* Include padding in width */
}

.form__label {
  position: absolute;
  top: -10px;
  left: 12px;
  font-size: 12px;
  color: #25848d; /* Label color */
  background: #ffffff; /* Background color to overlap the input */
  padding: 0 4px;
}

.contact_form_submit {
  background: linear-gradient(
    135deg,
    #00b4db,
    #0083b0
  ); /* Gradient background */
  border: none;
  border-radius: 25px; /* Rounded corners */
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  padding: 12px 24px;
  text-transform: uppercase;
  cursor: pointer;
  transition: background 0.3s, transform 0.3s;
  display: block;
  margin: 20px auto;
}

.contact_form_submit:hover {
  background: linear-gradient(135deg, #0083b0, #00b4db); /* Inverted gradient */
  transform: scale(1.05); /* Slightly larger on hover */
}

.contact_form_submit:disabled {
  background: #cccccc; /* Disabled button color */
  cursor: not-allowed;
}

/* Responsive styles */
@media (max-width: 767px) {
  .contact-form-card {
    padding: 15px;
    width: 90vw;
  }

  .contact-form-header {
    font-size: 20px; /* Smaller header on small screens */
  }

  .form__field {
    font-size: 14px; /* Smaller font size for inputs */
    padding: 10px; /* Smaller padding for inputs */
  }

  .contact_form_submit {
    font-size: 14px; /* Smaller font size for button */
    padding: 10px 20px; /* Smaller padding for button */
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .contact-form-card {
    padding: 20px;
    max-width: 90%; /* Adjust max width for medium screens */
  }

  .contact-form-header {
    font-size: 22px; /* Adjust header size for medium screens */
  }

  .form__field {
    font-size: 15px; /* Adjust font size for inputs */
    padding: 11px; /* Adjust padding for inputs */
  }

  .contact_form_submit {
    font-size: 15px; /* Adjust font size for button */
    padding: 11px 22px; /* Adjust padding for button */
  }
}
