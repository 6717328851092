.about-main-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.about-header-container {
  position: relative; /* Make sure the container is positioned */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 100px;
  width: 99vw;
  min-height: 70vh;
  background-image: url("../../assets/about-background.svg");
  background-repeat: no-repeat; /* Repeat the background image */
  background-size: 100%; /* Prevent stretching */
  background-position: top left; /* Position the image at the top left */
}

.mission-div {
  padding-top: 20px;
  padding-bottom: 20px;
  /* background-color: #f0f1f6; */
  height: auto;
}
