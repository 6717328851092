.section-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px;
  background: linear-gradient(135deg, #25848d0c 0%, #25848d3a 100%);
}

.section-card-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  align-items: center;
  padding: 50px;
}

.section-card {
  position: relative;
  width: 300px;
  min-height: 250px;
  padding: 30px 20px;
  background: linear-gradient(135deg, #25848d3a 0%, #ffffff 100%);
  border-radius: 25px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border: 5px solid white;
  height: 300px;
}

.section-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 40px rgba(0, 0, 0, 0.2);
}

.section-card:before {
  content: "";
  position: absolute;
  top: -50px;
  left: -50px;
  width: 200px;
  height: 200px;
  background: linear-gradient(135deg, #2cbef764, #fc575f43);
  border-radius: 50%;
  z-index: 0;
}

.section-card h3 {
  position: relative;
  margin-bottom: 20px;
  font-size: 30px;
  color: #333;
  z-index: 1;
}

.section-card p {
  position: relative;
  font-size: 16px;
  line-height: 1.6;
  color: #666;
  z-index: 1;
}

.section-card::after {
  content: "";
  position: absolute;
  bottom: -50px;
  right: -50px;
  width: 150px;
  height: 150px;
  background: linear-gradient(135deg, #2cbef764, #fc575f43);
  border-radius: 50%;
  z-index: 0;
}

/* Media Query for Mobile Phones (480px and below) */
@media (max-width: 641px) {
  .section-card h3 {
    font-size: 16px;
  }
}

/* Media Query for Tablets (768px and below) */
@media (min-width: 641px) and (max-width: 961px) {
  .section-card h3 {
    font-size: 20px;
  }
}
