.mission-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.fade-in {
  opacity: 0;
  transform: translateY(40px);
  transition: opacity 0.8s ease-out, transform 0.8s ease-out;
}

.fade-in.active {
  opacity: 1;
  transform: translateY(0);
}

@keyframes slideInAnimation {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes RotateDiv {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.values-img {
  margin: 20px;
  width: 40vw;
}

@media (max-width: 641px) {
  .values-img {
    width: 70vw;
  }
}

/* Media query for mobile screens */
@media (min-width: 641px) and (max-width: 961px) {
  .values-img {
    width: 80vw;
  }
}
