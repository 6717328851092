body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.main-service-header {
  font-size: 40px;
  font-weight: 600;
  color: #17565a;
  text-align: center;
}

@media (max-width: 641px) {
  /* smartphones, iPhone, portrait 480x320 phones */
  .main-service-header {
    font-size: 20px;
    width: 80vw;
  }
}

@media (min-width: 641px) and (max-width: 961px) {
  /* tablet, landscape iPad, lo-res laptops ands desktops */
  .main-service-header {
    font-size: 30px;
  }
}

@media (min-width: 961px) and (max-width: 1281px) {
  /* big landscape tablets, laptops, and desktops */
  .main-service-header {
    font-size: 30px;
  }
}
