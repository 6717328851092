.offer-main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
}

.fade-in {
  opacity: 0;
  transform: translateY(40px);
  transition: opacity 0.8s ease-out, transform 0.8s ease-out;
}

.fade-in.active {
  opacity: 1;
  transform: translateY(0);
}

.offer-container {
  margin: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap; /* Allow wrapping for smaller screens */
}

.offer-card {
  border-radius: 0px 0px 20px 20px;
  padding: 0px 16px 16px;
  box-shadow: 0 7px 20px rgba(0, 0, 0, 0.2);
  border: 0;
  width: 20vw;
  margin: 10px;
  background: linear-gradient(135deg, #25848d3a 0%, #ffffff 100%);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  height: 78vh; /* Fixed height for all cards */
  box-sizing: border-box;
}

.offer-card img {
  border-radius: 0px 0px 20px 20px;
  width: 12vw;
  margin-bottom: 20px;
}

.offer-card p {
  width: 100%;
  margin-top: 20px;
  text-align: justify;
  line-height: 1.6;
  word-wrap: break-word;
  overflow: hidden; /* Prevents content overflow */
  box-sizing: border-box;
  padding: 0 10px;
  flex-grow: 1; /* Allows the paragraph to expand and fill the available space */
}

@keyframes slideInAnimation {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes RotateDiv {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Media Query for Mobile Phones (480px and below) */
@media (max-width: 641px) {
  .offer-container {
    flex-direction: column; /* Stack the cards vertically */
  }

  .offer-card {
    width: 80vw;
    height: auto;
    margin: 10px 0;
  }

  .offer-card img {
    width: 60vw;
  }

  .offer-card p {
    width: 60vw;
    padding: 0 10px;
  }
}

/* Media Query for Tablets (768px and below) */
@media (min-width: 641px) and (max-width: 961px) {
  .offer-container {
    flex-direction: row; /* Stack the cards vertically */
  }

  .offer-card {
    width: 40vw;
    height: auto;
    margin: 10px 10px;
  }

  .offer-card img {
    width: 30vw;
  }

  .offer-card p {
    width: 30vw;
    padding: 0 10px;
  }
}

@media (min-width: 961px) and (max-width: 1281px) {
  /* big landscape tablets, laptops, and desktops */

  .offer-container {
    flex-direction: row; /* Stack the cards vertically */
  }

  .offer-card {
    width: 40vw;
    height: auto;
    margin: 10px 10px;
  }

  .offer-card img {
    width: 30vw;
  }

  .offer-card p {
    width: 30vw;
    padding: 0 10px;
  }
}
