/* @import url(https://maxcdn.bootstrapcdn.com/font-awesome/4.4.0/css/font-awesome.min.css);
@import url(https://fonts.googleapis.com/css?family=Raleway:400,800); */
.technologies-main-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url("../../assets/technology-background.svg");
  background-repeat: repeat; /* Repeat the background image */
  background-size: 100%; /* Prevent stretching */
  background-position: top left; /* Position the image at the top left */
}

.technologies-header-container {
  position: relative; /* Make sure the container is positioned */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 100px;
  min-height: 70vh;
}

.technologies-main-container p {
  text-align: center;
  color: #25848d;
}

.technology-description {
  margin: 20px;
  margin-bottom: 30px;
  width: 50vw;
  color: black;
  text-align: justify;
  font-size: 20px;
}

.helper-button-container {
  display: flex;
  gap: 20px;
  flex-direction: row;
}

.quote-mark {
  color: #25848d; /* Custom color for the quotation marks */
  font-weight: bold; /* Make the quotation marks bold */
  font-family: "cursive";
}

.technology-container {
  display: flex;
  flex-direction: column;
  padding: 40px 100px;
  gap: 100px;
}

.technology {
  background-color: rgba(
    37,
    132,
    141,
    0.1
  ); /* Lighter, more transparent background color */
  width: 70vw;
  border-radius: 24px;
  height: auto;
  backdrop-filter: blur(8px); /* Slightly less blur for a lighter effect */
  -webkit-backdrop-filter: blur(
    8px
  ); /* Ensure compatibility with WebKit browsers */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: subtle shadow for a lifted effect */
  border: 1px solid rgba(255, 255, 255, 0.2); /* Optional: add a slight border to enhance the glass effect */
  padding: 20px 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.technology-divider {
  border: none;
  border-top: 2px solid #25848d; /* Black line for visibility, adjust thickness */
  margin: 2px 0; /* Added margin to create space above and below */
  width: 10%; /* Ensure it spans the full width of its container */
  /* Optional: Center it horizontally if it's within a container */
  align-self: center;
  margin-bottom: 20px;
}

.technology p {
  font-size: 24px;
  color: #25848d;
}

.technology-list {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

@media (max-width: 641px) {
  .technology-description {
    margin: 20px;
    margin-bottom: 30px;
    width: 80vw;
    color: black;
    text-align: justify;
    font-size: 14px;
  }
}
