@keyframes expand {
  from {
    transform: scale(0);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

.info-containers {
  display: flex;
  padding: 50px 40px;
  justify-content: space-evenly;
  align-items: center;
  opacity: 0;
  height: 67vh;
}

.info-containers-visible {
  transform-origin: center;
  animation: expand 1s ease-out forwards;
  opacity: 1;
  transform: translateY(0);
}

.divider {
  border: none;
  border-top: 2px solid #000; /* Black line for visibility, adjust thickness */
  margin: 5px 0; /* Added margin to create space above and below */
  width: 25%; /* Ensure it spans the full width of its container */
  /* Optional: Center it horizontally if it's within a container */
  align-self: center;
}

.category-shape1 {
  padding: 20px 10px;
  width: 22vw;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  background-color: #25848d3a;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  height: 80%;
}

.category-img {
  width: 80%;
  height: 80%;
}

.innovation-img {
  width: 65%;
  height: 60%;
}

.category-heading {
  font-size: 22px;
  font-weight: 600;
  text-align: center;
  margin-top: 15px;
}

.category-description {
  text-align: center;
  margin-top: 10px;
}

.category-shape2 {
  padding: 20px 10px;
  width: 22vw;
  background-color: #25848d3a;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  height: 80%;
}

.category-shape3 {
  padding: 20px 10px;
  width: 22vw;
  background-color: #25848d3a;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  height: 80%;
}

@media (max-width: 961px) {
  .info-containers {
    flex-direction: column;
    padding: 40px;
    height: auto;
  }

  .category-shape1 {
    width: 60vw;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    border-bottom-left-radius: 0;
  }

  .category-shape2 {
    width: 60vw;
    margin-top: 10px;
  }

  .category-shape3 {
    width: 60vw;
    margin-top: 10px;
    border-top-right-radius: 0;
    border-bottom-left-radius: 30px;
  }
}

@media (max-device-width: 641px) {
  /* smartphones, iPhone, portrait 480x320 phones */
  .category-heading {
    font-size: 16px;
  }

  .category-description {
    font-size: 12px;
  }
}
