/* CSS */
.home {
  height: 92vh;
  /* margin-top: -40pt; */
  display: flex;
  /* justify-content: center; */
  /* align-content: center; */
  flex-direction: row;
  /* align-items: center; */
  /* padding-left: 10%; */
  /* padding-right: 10%; */
  /* background-color: #25848d1a; */
  /* background: linear-gradient(135deg, #25848d0c 0%, #25848d3a 100%); */
}

.home-title {
  font-size: 50px;
  font-weight: 600;
  color: #6677a8;
}

.home-description {
  font-size: 50px;
  font-weight: 600;
  color: #17565a;
  text-align: center;
}

.more-info-title {
  font-size: 40px;
  font-weight: 600;
  color: #6677a8;
}

.sub-title {
  font-size: 30px;
  font-weight: 500;
  color: #6677a8;
}

.home-info-div-enter {
  opacity: 0.01;
}

.home-info-div-enter-active {
  opacity: 1;
  transition: "opacity 300ms ease-in";
}
.home-title-div {
  padding: 20px;
  width: 40%;
  height: 80vh;
  opacity: 0;
  transform: translateY(30px);
  transition: opacity 0.8s ease-in-out, transform 0.8s ease-in-out;
  /* background-color: #f0f1f6; */
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-title-div-visible {
  /* Transition to visible state */
  opacity: 1;
  transform: translateY(0);
}

.home-lottie-animation {
  /* display: none; */
  width: 50vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-lottie-animation2 {
  /* display: none; */
  margin-top: -50px;
  height: 600px;
}

.more-info-div {
  display: flex;
  height: auto;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* background-color: #F0F1F6; */
  padding-left: 5%;
  padding-right: 5%;
}

.more-info-title-div {
  width: 50%;
}

@media (max-device-width: 641px) {
  /* smartphones, iPhone, portrait 480x320 phones */
  .home {
    height: auto;
    display: flex;
    flex-direction: column;
  }
  .home-title {
    font-size: 20px;
    font-weight: 600;
    color: #6677a8;
  }
  .home-title-div {
    width: 100vw;
    height: auto;
    /* transform: translate(-50%, 0%); */
  }

  .home-description {
    font-size: 20px;
  }

  .sub-title {
    font-size: 15px;
    font-weight: 600;
    color: #6677a8;
    display: flex;
    justify-content: center;
    text-align: center;
  }

  .more-info-title {
    font-size: 20px;
    font-weight: 600;
    color: #6677a8;
    display: flex;
    justify-content: center;
    text-align: center;
    margin-top: -20pt;
  }

  .home-lottie-animation {
    /* display: none; */
    width: 100vw;
    margin-top: 50px;
    display: flex;
    justify-content: center;
    /* position: absolute; */
  }

  .home-lottie-animation2 {
    /* display: none; */
    display: flex;
    height: 200px;
    width: 400px;
  }

  .more-info-div {
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* background-color: #F0F1F6; */
  }
}
@media (min-width: 641px) and (max-width: 961px) {
  /* tablet, landscape iPad, lo-res laptops ands desktops */
  .home-title-div {
    width: 100vw;
    height: auto;
  }
  .home {
    height: auto;
    display: flex;
    flex-direction: column;
  }
  .home-title {
    font-size: 30px;
    font-weight: 600;
    color: #6677a8;
    display: flex;
  }

  .home-description {
    font-size: 25px;
  }

  .sub-title {
    font-size: 15px;
    font-weight: 600;
    color: #6677a8;
    display: flex;
    justify-content: center;
    text-align: center;
    margin-top: -20pt;
  }

  .more-info-title {
    font-size: 25px;
    font-weight: 600;
    color: #6677a8;
    display: flex;
    justify-content: center;
    text-align: center;
    margin-top: -80pt;
  }

  .home-lottie-animation {
    /* display: none; */
    display: flex;
    justify-content: center;
    width: 100vw;
    /* position: absolute; */
  }

  .home-lottie-animation2 {
    /* display: none; */
    display: flex;
    height: 400px;
    width: 400px;
  }

  .more-info-div {
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* background-color: #F0F1F6; */
  }
}
@media (min-width: 961px) and (max-width: 1281px) {
  /* big landscape tablets, laptops, and desktops */
  .home {
    height: 100vh;
    /* margin-top: -40pt; */
    display: flex;
    flex-direction: row;
  }

  .home-lottie-animation {
    /* display: none; */
    margin-top: -100px;
    /* position: absolute; */
  }

  .home-title {
    font-size: 30px;
    font-weight: 600;
    color: #6677a8;
  }

  .home-description {
    font-size: 40px;
  }

  .home-title-div {
    width: 40%;
    top: 50%;
    left: 50%;
  }

  .more-info-title {
    font-size: 30px;
    font-weight: 600;
    color: #6677a8;
  }

  .sub-title {
    font-size: 15px;
    font-weight: 500;
    color: #6677a8;
  }
}
